//created by Peggy on 2021/5/22
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Breadcrumb, Button, Form, Input, message, Select } from "antd";

import { contributionDetail, contributionThankyouAddr, contributionThankyouResend } from "@/adminApp/apis/finance-api";

import {
  DownloadPdf,
  Template,
  ArtistTemplate,
  TheFieldTemplate,
} from "@/pages/dashboard/common/orderNote/note";

import styled from "styled-components";
import useConfirm from "@/components/Modal/Confirm";
import { useForm } from "antd/es/form/Form";
import { stateList } from "@/types/enum";
import qs from 'query-string';
import { admin_router_crowd_field, admin_router_crowd_field_donation_detail, admin_router_finance_contribution, admin_router_finance_contribution_order } from "@/adminApp/routers";

const NoteContainer = styled.div`
  padding: 40px;
  background-color: #f1f1f6;
  h1 {
    margin-bottom: 40px;
  }
  .card-wrap {
    flex: 1;
    padding: 32px;
    margin-bottom: 24px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
  }
`;

const Note = () => {
  const history = useHistory();
  let { id } = useParams() as any;
  const { crowd } = qs.parse(history.location.search)

  const [info, setInfo] = useState<any>();
  const [editForm] = useForm()
  const editSave = (data: any) => {
    contributionThankyouAddr(id, data).then(res => res?.success && getDetail())

  }
  const fillEditInfo = (data?: any) => {
    data = data || info
    editForm.setFieldsValue({
      ty_name: data?.issuedTo?.ty_name || data?.issuedTo?.name,
      ty_address: data?.issuedTo?.ty_address || data?.issuedTo?.addressInfo?.address,
      ty_city: data?.issuedTo?.ty_city || data?.issuedTo?.addressInfo?.city,
      ty_state: data?.issuedTo?.ty_state || data?.issuedTo?.addressInfo?.state,
      ty_zipCode: data?.issuedTo?.ty_zipCode || data?.issuedTo?.addressInfo?.zipCode,
    })
  }

  const editError = (err: any) => {
    message.error(err.errorFields[0].errors[0])
    fillEditInfo()
  }

  const { setShow: showEdit, component: editComp } = useConfirm(
    <div className="edit-ty-note">
      <div className="des">You are editing the thank you note for donation for order #{info?.orderId}.
        Please note that you can only edit the donor name and the address.
        Information such as amount and fee are not editable.
      </div>
      <br />
      <h3>Recipient information</h3>
      <Form form={editForm} onFinish={editSave} onFinishFailed={editError} layout="vertical">
        <Form.Item
          name="ty_name"
          label="Donor name"
          rules={[
            {
              required: true,
              message: 'Donor Name is required'
            }
          ]}
        >
          <Input placeholder="input donor name" />
        </Form.Item>
        <Form.Item
          name="ty_address"
          label="Address"
          rules={[
            {
              required: true,
              message: 'Donor Address is required'
            }
          ]}
        >
          <Input placeholder="Address" />
        </Form.Item>
        <Form.Item
          label="City"
          name="ty_city"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "City",
            },
          ]}
        >
          <Input autoComplete="off" maxLength={30} placeholder="City" />
        </Form.Item>
        <Form.Item
          label="State"
          name="ty_state"
          rules={[
            {
              whitespace: true,
              message: "State",
            },
          ]}
        >
          <Select
            placeholder="State"
            bordered={false}
          >
            {Object.values(stateList)?.map((item) => (
              <Select.Option value={item} key={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Zip Code"
          name="ty_zipCode"
          normalize={(e) => {
            return e.replace(/[^\d]*/g, "");
          }}
          rules={[
            {
              required: false,
              whitespace: true,
              message: "Zip code",
            },
          ]}
        >
          <Input
            autoComplete="off"
            maxLength={5}
            placeholder="Enter zip code"
          />
        </Form.Item>
      </Form>
    </div>,
    {
      title: 'Edit thank you note info',
      okText: 'SAVE',
      onOk: editForm.submit,
    }
  )

  const [resendForm] = useForm()
  const resendNote = (data: any) => {
    contributionThankyouResend(id, data).then(res => {
      res?.success && message.success('Resend Thank you note successfully!')
      resendForm.setFieldsValue({ email: undefined })
      showResend(false)
    })
  }
  const resendFail = (err: any) => {
    message.error(err.errorFields[0].errors[0])
  }
  const { setShow: showResend, component: resendComp } = useConfirm(
    <div>
      <div className="des">
        You are resending thank you note for order #{info?.orderId}.
        This action cannot be undone, so please review the content carefully.
      </div>
      <br />
      <Form form={resendForm} onFinish={resendNote} onFinishFailed={resendFail} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          rules={[{
            type: 'email',
            required: true,
            message: 'Email required'
          }]}
        >
          <Input placeholder="Enter email" />
        </Form.Item>
      </Form>
    </div>,
    {
      title: 'Resend thank you note',
      onOk: resendForm.submit
    }
  )
  const getDetail = async () => {
    const data = await contributionDetail(id);
    setInfo(data)
    fillEditInfo(data)
  }

  useEffect(() => {
    // eslint-disable-next-line
    getDetail()
  }, []);
  return (
    <NoteContainer className="note-container">
      {
        crowd ? <Breadcrumb separator=">">
          <Breadcrumb.Item
            href={admin_router_crowd_field}
          >
            The Field Crowdfunding
          </Breadcrumb.Item>
          <Breadcrumb.Item
            href={admin_router_crowd_field_donation_detail.replace(':id', crowd.toString())}
          >
            Campaign detail
          </Breadcrumb.Item>
          <Breadcrumb.Item
            href={`/finance/contribution/${id}?crowd=${crowd}`}
          >
            Donation detail
          </Breadcrumb.Item>
          <Breadcrumb.Item>Thank you note</Breadcrumb.Item>

        </Breadcrumb> :
          <Breadcrumb separator=">">
            <Breadcrumb.Item
              href={
                history.location.pathname.indexOf("contribution") > -1
                  ? `/finance/contribution`
                  : "/finance/order"
              }
            >
              Contributions
            </Breadcrumb.Item>
            <Breadcrumb.Item
              href={
                history.location.pathname.indexOf("contribution") > -1
                  ? `/finance/contribution/${id}`
                  : `/finance/order/${id}`
              }
            >
              Order details
            </Breadcrumb.Item>
            <Breadcrumb.Item>Thank you note</Breadcrumb.Item>
          </Breadcrumb>
      }
      <div className="note-wrap">
        <div className="flex">
          <h1>Thank You Note</h1>
          <div className="btns" hidden={!(['processing', 'paid'] as const).includes(info?.status)}>
            <Button onClick={() => showEdit(true)}>Edit</Button>
            <Button onClick={() => showResend(true)} style={{ marginLeft: 20 }} type="primary">Resend</Button>
          </div>
        </div>
        <div className="content-wrap card-wrap">
          {info?.type === "donation_to_artist" ? (
            <ArtistTemplate user={info} />
          ) : info?.type === "donation_to_the_field" || info?.type === "donation_to_the_field_crowd" ? (
            <TheFieldTemplate user={info} />
          ) : (
            <Template user={info} />
          )}
        </div>
        <div className="flex end">
          <DownloadPdf user={info} />
        </div>
      </div>
      {editComp}
      {resendComp}
    </NoteContainer>
  );
};

export default Note;
